import {Component} from '@angular/core'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {switchMap} from 'rxjs/operators'
import {HttpErrorResponse} from '@angular/common/http'
import {BankIdService} from '../../services/bank-id.service'
import {WaitDialogComponent} from '../wait-dialog/wait-dialog.component'
import {SignDialogComponent} from '../sign-dialog/sign-dialog.component'
import {StartResponse} from '../../application/data-types'
import {ConfigService} from '../../services/config.service'

/**
 * Calls BankID as necessary and when done (or failed)
 * it emits an event that (someone) can listen to.
 */
@Component({
  selector: 'spb-bankid',
  templateUrl: './bankid.component.html',
  styleUrls: ['./bankid.component.scss']
})
export class BankidComponent {
  /**
   * A general error message
   */
  public errorMessage = ''

  /**
   * Details of the error.
   */
  public errorDetail = ''

  /**
   * Keep a reference "globally" to the sign dialog
   */
  public signDialogRef: MatDialogRef<SignDialogComponent> | undefined

  /**
   * A spinner that is displayed if the user has received an OK from bankID
   */
  public spinner = false

  /**
   * Default constructor
   */
  constructor(
    private bankIdService: BankIdService,
    private configService: ConfigService,
    private dialog: MatDialog
  ) {
  }

  /**
   * Set wait state and try to log in.
   *
   * @param other - 'other' is login on 'other device', id est start here or show qr.
   */
  public login(other: boolean): void {
    this.errorMessage = ''
    const waitRef = this.dialog.open(WaitDialogComponent, {
      disableClose: true,
      data: {
        title: 'Förbereder BankID',
        message: 'Vi förbereder för BankID, var god dröj...'
      }
    })
    waitRef.afterOpened().pipe(
      switchMap(() => this.bankIdService.startLogin()),
      switchMap((startResponse: StartResponse) => {
          this.signDialogRef = this.dialog.open(SignDialogComponent, {
            width: '90%',
            maxWidth: '400px',
            disableClose: true,
            data: {
              type: 'login',
              orderRef: startResponse.orderRef,
              title: 'Legitimera',
              actionText: 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera.',
              doneText: 'Legitimering klar. Ha en fin dag!',
              startResponse,
              other
            }
          })
          waitRef.close()
          return this.signDialogRef.afterClosed()
        }
      )
    )
      .subscribe({
        next: ((res: any) => {

          if (res.status === 'ok') {
            this.spinner = true
          }

          if (res.status === 'bankIDTimeout') {
            this.errorMessage = 'Tidsgränsen tog slut, vänligen försök igen'
          }

          if (res.accessToken) {
            this.configService.setToken(res.accessToken)
            this.configService.getCurrentUser().subscribe()
          }

          if (res.status === 'error') {
            this.errorMessage = 'Kunde inte starta BankID'
          }
        }),
        error: (err: HttpErrorResponse) => {
          this.errorMessage = 'Kunde inte starta BankID'
          this.errorDetail = err.error.errorMessage
        }
      })
  }
}
