import {Component, OnDestroy, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService, SpbConfiguration} from '../../services/config.service'
import {Subscription} from 'rxjs'
import {filter} from 'rxjs/operators'
import {User} from '../../services/types'
import {IS_RT_ADMIN_ROLE} from '../../application/data-types'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * The current user.
   */
  public user: User = {itemId: '', roles: [], sId: '', sub: '', name: ''}

  /**
   * Tells if admin functions are available.
   */
  public isRtAdmin = false

  /**
   * Hide the menu etc. if not logged in at all.
   */
  public ready = false

  /**
   * Hide the menu from non
   */
  public isRtUser = false

  /**
   * Holds the subscription of config and currentUser.
   */
  private config$ = new Subscription()
  private currentUser$ = new Subscription()

  constructor(
    public configService: ConfigService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.config$ = this.configService.configState$
      .pipe(
        filter((config: SpbConfiguration) => config.ready)
      )
      .subscribe({
        next: (config: SpbConfiguration) => {
          this.ready = config.ready
          this.isRtUser = config.boUser
        }
      })

    /**
     * Get the current user and give admin rights if the user has the correct role
     */
    this.currentUser$ = this.configService.currentUser$
      .subscribe({
        next: (user: User) => {
          this.user = user
          this.isRtAdmin = user.roles.indexOf(IS_RT_ADMIN_ROLE) !== -1
        }
      })
  }

  public ngOnDestroy(): void {
    this.config$.unsubscribe()
    this.currentUser$.unsubscribe()
  }

  public logout(): void {
    this.configService.resetToken()
    this.user.name = ''
    this.router.navigate(['login']).then()
  }
}
