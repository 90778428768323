import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {StartResponse} from '../application/data-types'

export interface CollectResponse {
  /**
   * The order reference
   */
  orderRef: string

  /**
   * The status of the order
   */
  status: string

  /**
   * A hint of what went wrong
   */
  hintCode: string

  /**
   * The access token, in form of a jwt to use for further access
   */
  accessToken: string

  /**
   * Present if something is broken
   */
  errorCode: string
}

@Injectable({
  providedIn: 'root'
})
export class BankIdService {
  /**
   * We need a client
   */
  constructor(
    private httpClient: HttpClient
  ) {
  }

  public startLogin(): Observable<StartResponse> {
    const url = `${environment.authServiceUrl}/login/start`
    const data = {
      domain: environment.domain,
      type: 'auth',
      scope: 'auth'
    }
    return this.httpClient.put<StartResponse>(url, data)
  }

  public collect(orderRef: string): Observable<CollectResponse> {
    const url = `${environment.authServiceUrl}/login/collect?orderRef=${orderRef}`
    return this.httpClient.get<CollectResponse>(url)
  }

  public cancel(orderRef: string): Observable<CollectResponse> {
    const url = `${environment.authServiceUrl}/login/cancel?orderRef=${orderRef}`
    return this.httpClient.get<CollectResponse>(url)
  }

  /**
   * Get image, which represents a qrAuthCode, from database
   *
   * @param orderRef
   */
  public getImage(orderRef: string): Observable<string> {
    const url = `${environment.authServiceUrl}/login/image?orderRef=${orderRef}`
    return this.httpClient.get<string>(url)
  }
}
