<div>
  <ol>
    <li>Öppna appen för BankID.</li>
    <li>Tryck på QR-symbolen i appen för BankID.</li>
    <li>Rikta kameran mot QR-koden.</li>
    <li>Följ instruktionerna i appen för att logga in.</li>
  </ol>
  <div class="qr-code">
    <canvas #myCanvas height="204" width="204"></canvas>
  </div>
</div>

