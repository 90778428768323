import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {MatDialogModule} from '@angular/material/dialog'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {FooterComponent} from '../home/footer/footer.component'
import {BankidComponent} from './bankid/bankid.component'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'
import {SignDialogComponent} from './sign-dialog/sign-dialog.component'
import {QrCodeComponent} from './qr-code/qr-code.component'
import {MatButtonModule} from '@angular/material/button'
import {HeaderComponent} from '../home/header/header.component'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    BankidComponent,
    WaitDialogComponent,
    SignDialogComponent,
    QrCodeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule
  ],
  exports: [
    FooterComponent,
    BankidComponent,
    HeaderComponent
  ]
})

export class SpbCommonModule {
}
