<div matDialogTitle>{{data.title}}</div>
<span *ngIf="!other">{{statusText}}</span>
<div matDialogContent>
  <div *ngIf="other">
    <spb-qr-code [orderRef]="orderRef"></spb-qr-code>
  </div>
</div>
<mat-dialog-actions>
  <button (click)="cancel()"
          [matDialogClose]="{status: 'cancel'}"
          mat-stroked-button
  >Avbryt
  </button>
</mat-dialog-actions>
