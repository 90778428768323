<div *ngIf="spinner" class="mat-spinner">
  <mat-spinner></mat-spinner>
</div>


<div *ngIf="!spinner">
  <div *ngIf="errorMessage" class="spb-error">
    <div>{{errorMessage}}</div>
    <div class="error-detail">({{errorDetail}})</div>
  </div>
  <button
    (click)="login(false)" color="primary"
    mat-raised-button>
    BankID på Denna enhet
  </button>
  <button
    (click)="login(true)" color="primary"
    mat-raised-button>
    BankID på Annan enhet
  </button>

</div>
