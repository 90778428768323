import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {Observable, of, Subject, Subscription, timer} from 'rxjs'
import {filter, map, switchMap, take} from 'rxjs/operators'
import {BankIdService, CollectResponse} from '../../services/bank-id.service'
import {SignDialogData} from '../../application/data-types'
import {WINDOW} from '../../application/window.provider'

@Component({
  selector: 'spb-sign-dialog',
  templateUrl: './sign-dialog.component.html'
})
export class SignDialogComponent implements OnInit {

  /**
   * Temp for test
   */
  public statusText = ''

  /**
   */
  public errorMessage = ''

  /**
   * Other device, that is show QR
   */
  public other = false

  /**
   * Needed by QR
   */
  public qrStartToken = ''

  public orderRef = ''

  /**
   * Call this to cancel, the counter continues forever
   * if we do not explicitly cancel it.
   */
  public cancelQr = new Subject()
  /**
   * The subscription to check for BankID completion.
   */
  private countDown: Subscription = new Subscription()

  private userTexts: any = {
    userCancel: 'Avbrutet i BankID applikationen',
    outstandingTransaction: 'Försöker starta BankID-appen',
    userSign: 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera'
  }

  constructor(
    public dialogRef: MatDialogRef<SignDialogComponent>,
    private bankIdService: BankIdService,
    @Inject(WINDOW) private window: Window,
    @Inject(MAT_DIALOG_DATA) public data: SignDialogData
  ) {
  }

  /**
   *
   */
  public loginOnThisDevice(): Observable<any> {
    if (!this.other) {
      const loginOnThisDeviceLink = `bankid:///?autostarttoken=${this.data.startResponse.autoStartToken}&redirect=null`
      /**
       * We should figure out a way to detect failures...
       */
      return of(this.window.open(loginOnThisDeviceLink, '_parent'))
    } else {
      return of({})
    }
  }

  ngOnInit(): void {
    this.other = this.data.other
    this.qrStartToken = this.data.startResponse.qrStartToken
    this.orderRef = this.data.startResponse.orderRef
    this.countDown = this.loginOnThisDevice()
      .pipe(
        switchMap(() => timer(0, 2000)),
        take(60),
        switchMap(() => this.bankIdService.collect(this.data.orderRef)),
        map((response: CollectResponse): boolean => {
          this.statusText = this.userTexts[response.hintCode]

          if (response.hintCode === 'userCancel') {
            this.statusText = this.userTexts[response.hintCode]
            this.dialogRef.close({
              status: 'cancel'
            })
            this.cancel()
          }

          if (response.accessToken) {
            this.dialogRef.close({
              status: 'ok',
              accessToken: response.accessToken
            })
            return true
          }
          return false
        }),
        filter((res: boolean) => res),
        map(() => this.countDown.unsubscribe())
      )
      .subscribe({
        complete: () => {
          this.dialogRef.close({status: 'cancel'})
        },
        error: (error) => {
          this.countDown.unsubscribe()
          this.dialogRef.close({status: 'error', message: error.message})
        }
      })
  }

  cancel(): void {
    this.countDown.unsubscribe() // If user press cancel we need to unsubscribe
    this.bankIdService.cancel(this.data.orderRef).subscribe()
    this.dialogRef.close({status: 'cancel'})
  }
}
