import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {HttpClient} from '@angular/common/http'
import {catchError, tap} from 'rxjs/operators'
import {environment} from '../../environments/environment'

const ACCESS_TOKEN_NAME = 'bo-token'

export const USER_ROLE = 'boAppUser'

/**
 * An SPB user is a sparbanken employee
 */
export interface SpbUser {
  /**
   * The database Id
   */
  itemId: string

  /**
   * Typically s-ID (s209856)
   */
  sId: string

  /**
   * Personnummer
   */
  sub: string

  /**
   * Full name like Daniel Bergdahl
   */
  name: string

  /**
   *
   */
  phone?: string

  /**
   *
   */
  pet?: string

  /**
   * An array of roles, like "admin", "credit"...
   */
  roles: string[]

  /**
   * We can have an office
   */
  office?: string

  /**
   * Emails is good and should be mandatory
   */
  email?: string
}

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * boUser - Can use the tool
   */
  boUser: boolean

  /**
   * Set by the HttpIncerceptor when a token is ready to be used
   */
  interceptorReady?: boolean

  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  ready: boolean
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configState$: Observable<SpbConfiguration>

  /**
   * Publish and subscribe to the currently logged in user here...
   */
  public currentUser$: Observable<SpbUser>
  private pCurrentUser$: ReplaySubject<SpbUser> = new ReplaySubject<SpbUser>(1)

  /**
   * Publish and subscribe to the current SpbConfiguration here...
   */
  private currentConfig: SpbConfiguration = {ready: false} as any
  private pConfigState$: BehaviorSubject<SpbConfiguration> =
    new BehaviorSubject<SpbConfiguration>({ready: false} as any)


  constructor(
    private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.configState$ = this.pConfigState$.asObservable()
    this.currentUser$ = this.pCurrentUser$.asObservable()
  }

  private static validateTokenTimeStamp(token: string | null | undefined): boolean {
    if (!token) {
      return false
    }
    const payload = JSON.parse(atob(token.split('.')[1]))
    return payload.exp > (new Date().getTime() /*+ (60 * 60 * 11.6 * 1000)*/)
  }

  public checkStatus = (): Observable<any> => {
    this.isLoggedIn()
    if (this.currentConfig.ready) {
      return this.getCurrentUser()
        .pipe(
          catchError(() => of([]))
        )
    }
    return of({})
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    const token = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    // The set token will throw if not a valid string
    try {
      this.setToken(token as string)
    } catch (e) {
      // Can happen and is quite common.
    }
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string): void {
    const payload = JSON.parse(atob(token.split('.')[1]))
    this.currentConfig = {
      token: ConfigService.validateTokenTimeStamp(token) ? token : this.resetToken(),
      boUser: false,
      ready: false
    }
    if (this.currentConfig.token) {
      this.currentConfig.boUser = payload.roles.indexOf(USER_ROLE) !== -1
      this.currentConfig.ready = this.currentConfig.boUser
      this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token)
    }
    this.pConfigState$.next(this.currentConfig)
  }

  /**
   * Reset what ever access token we might have had, used when logging out
   */
  public resetToken(): null {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig = {ready: false} as any
    this.pConfigState$.next(this.currentConfig)
    return null
  }

  public getAccessToken(): string | null | undefined {
    return ConfigService.validateTokenTimeStamp(this.currentConfig.token) ? this.currentConfig.token : null
  }

  public getCurrentUser(): Observable<SpbUser> {
    const url = `${environment.authServiceUrl}/users/self`
    return this.httpClient.get<SpbUser>(url).pipe(
      tap((user: SpbUser) => this.pCurrentUser$.next(user))
    )
  }

  /**
   * The application configuration from the backend.
   */
  public getOffices(): Observable<string[]> {
    const url = `${environment.authServiceUrl}/data/offices`
    return this.httpClient.get<string[]>(url)
  }
}
